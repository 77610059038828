<template>
  <div v-loading="pageloading">
    <el-form :model="ruform" :rules="rules" ref="ruform" label-width="100px" style="margin-bottom:100px">
      <el-card>
        <div slot="header">
          <span>视频信息</span>
        </div>
        <el-form-item label="带货商品：" prop="ProductId">
          <el-select v-model="ruform.ProductId" style="width:400px" placeholder="选择带货商品" class="maxwidthHidden" :disabled="$route.query.Id>0"
           @visible-change="visiblechange" popper-class="zindex99">
            <el-option v-for="(v,i) in shoplist" :key="i" :label="v.Name" :value="v.ProductId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品售价：" v-if="ruform.ProductId">
          ￥{{goods.ProductPrice}}<span v-if="goods.ProductMaxPrice>goods.ProductPrice">~￥{{goods.ProductMaxPrice}}</span>
        </el-form-item>
        <el-form-item label="视频描述：">
          <el-input v-model="ruform.Describe" type="textarea" placeholder="视频描述最多500字，若为空，默认使用带货商品名称" :rows="6" style="width:400px"
            maxlength="500"></el-input>
        </el-form-item>
        <el-form-item label="视频封面：" prop="CoverImgUrl">
          <div class="flex">
            <el-upload :action="imgApi" accept="image/*" list-type="picture-card" :show-file-list="false" :on-success="onSuccessimg"
             class = "uploadBox" :before-upload ="beforeUploadimg">
              <img v-if="ruform.CoverImgUrl" :src="imgUrl+ruform.CoverImgUrl" style="width:160px;height:200px;border-radius:5px" alt="">
              <i v-else slot="default" class="el-icon-plus"></i>
            </el-upload>
            <div class="introduce">
              <div>封面图上传建议：</div>
              <div>(1) 建议图片宽高比3:4</div>
              <div>(2) 图片大小不超过1M</div>
            </div>
          </div>
        </el-form-item>
      </el-card>
      <el-card style="margin-top:10px">
        <div slot="header">
          <span>视频片段</span>
        </div>
        <div class="list-item" v-for="(v,i) in ruform.ShortVideoSceneList" :key="i">
          <div class="item-titlebox">
            <div class="title-font">场景{{i+1}}</div>
            <div>
              <el-button type="text" style="color:#f56c6c;margin:0px;padding:0px" @click="delscenario(i)"
               v-if="ruform.ShortVideoSceneList.length>1">删除</el-button>
            </div>
          </div>
          <div class="item-body">
            <el-form-item label="截取时长：" :prop="'ShortVideoSceneList.'+i+'.InterceptionTime'" :rules="rules.InterceptionTime" :key="'InterceptionTime'+v.key">
              <div class="flexRow">
                <el-input v-model="v.InterceptionTime" style="width:100px;margin-right:10px" onkeyup="value=value.replace(/[^0-9.]/g,'')"></el-input>
                <span>秒</span>
              </div>
            </el-form-item>
            <el-form-item label="视频片段：" :prop="'ShortVideoSceneList.'+i+'.VideoList'" :rules="rules.VideoList"  :key="'VideoList'+v.key">
              <div class="defaultfont" style="margin-top:8px">每个场景下，最多添加30个视频片段</div>
              <div class="rowflex-wrap">
                <el-upload :action="videoApi" ref="videofile" accept="video/mp4" list-type="picture-card" multiple :show-file-list="false"
                 :on-success="($event,file,filelist)=>{onSuccessvideo($event,file,filelist,i)}" style="margin:10px 20px 10px 0px" class="size120"
                  :before-upload ="($event)=>{return beforeUploadvideo($event,i)}">
                  <i slot="default" class="el-icon-plus"></i>
                </el-upload>
                <div class="videobox size120" v-for="(x,y) in v.VideoList" :key="y" v-loading="x.loading">
                  <img :src="imgUrl+x.VideoLink+'?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast'" v-if="x.noplay&&x.isold"
                   style="max-width:100%;max-height:100%"  alt="" @click="playvideo(i,y)" :key="x.VideoLink+i+y">
                  <video v-else :src="imgUrl+x.VideoLink" :key="x.VideoLink+i+y" ref="video" style="max-width:100%;max-height:100%" :data-uid="x.uid" autoplay controls>
                    <source>
                  </video>
                  <div class="close" @click="delvideo(i,y)">
                    <div class="iconbox">
                      <i class="el-icon-error"></i>
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="list-item" v-if="ruform.ShortVideoSceneList.length<20">
          <div class="item-titlebox">
            <el-button type="text" style="margin:0px;padding:0px" @click="pushscenario">添加场景</el-button>
            <div></div>
          </div>
        </div>
      </el-card>
      <el-card style="margin-top:10px">
        <div slot="header">
          <span>音频/字幕文件</span>
        </div>
        <el-form-item :label="'文件'+(i+1)+'：'" v-for="(v,i) in ruform.ShortVideoFileList" :key="i" required>
          <div class="flex flex-end">
            <div class="otherfile">
              <el-form-item label="音频文件：" :prop="'ShortVideoFileList.'+i+'.AudioFile'" :rules="rules.AudioFile" class="adjust-err" style="margin:5px 0px">
                <div class="flexRow" v-loading="v.AudioFileloading">
                  <div class="defaultfont" v-if="v.AudioFile">{{v.AudioFileName}}</div>
                  <el-upload :action="fileApi" accept="audio/mp3" list-type="text" :show-file-list="false" :on-success="(e)=>{onSuccessfile(e,i)}"
                  :before-upload ="(e)=>{beforeUploadfile(e,i)}" style="margin:0px 10px">
                    <div class="flexRow">
                      <el-button type="text" style="margin:0px;padding:0px">{{v.AudioFile?'重新选择':'选择文件'}}</el-button>
                    </div>
                  </el-upload>
                </div>
              </el-form-item>
              <!-- <div class="flexRow">
                <div class="defaultfont">音频文件：</div>
                <div class="defaultfont" v-if="v.AudioFile">{{v.AudioFileName}}</div>
                <el-upload :action="fileApi" accept="audio/mp3" list-type="text" :show-file-list="false" :on-success="(e)=>{onSuccessfile(e,i)}"
                :before-upload ="(e)=>{beforeUploadfile(e,i)}" style="margin:0px 10px">
                  <div class="flexRow">
                    <el-button type="text">{{v.AudioFile?'重新选择':'选择文件'}}</el-button>
                  </div>
                </el-upload>
              </div> -->
              <el-form-item label="字幕文件：" :prop="'ShortVideoFileList.'+i+'.SubtitleFile'" :rules="rules.SubtitleFile" class="adjust-err" style="margin:5px 0px">
                <div class="flexRow" v-loading="v.SubtitleFileloading">
                  <div class="defaultfont" v-if="v.SubtitleFile">{{v.SubtitleFileName}}</div>
                  <el-upload :action="fileApi" accept="" list-type="text" :show-file-list="false" :on-success="(e)=>{onSuccessfile2(e,i)}"
                  :before-upload ="(e)=>{beforeUploadfile2(e,i)}" style="margin:0px 10px">
                    <div class="flexRow">
                      <el-button type="text" style="margin:0px;padding:0px">{{v.SubtitleFile?'重新选择':'选择文件'}}</el-button>
                    </div>
                  </el-upload>
                </div>
              </el-form-item>
              <!-- <div class="flexRow">
                <div class="defaultfont">字幕文件：</div>
                <div class="defaultfont" v-if="v.SubtitleFile">{{ruform.SubtitleFileName}}</div>
                <el-upload :action="fileApi" accept="" list-type="text" :show-file-list="false" :on-success="(e)=>{onSuccessfile2(e,i)}"
                :before-upload ="(e)=>{beforeUploadfile2(e,i)}" style="margin:0px 10px">
                  <div class="flexRow">
                    <el-button type="text">{{v.SubtitleFile?'重新选择':'选择文件'}}</el-button>
                  </div>
                </el-upload>
              </div> -->
            </div>
            <el-button type="text" style="color:#f56c6c;margin-left:20px" @click="delShortVideoFile(i)">删除文件</el-button>
          </div>
        </el-form-item>
        <el-button type="primary" plain style="" @click="pushShortVideoFile" v-if="ruform.ShortVideoFileList.length<20">添加音频/字幕文件</el-button>
      </el-card>
      <el-card style="margin-top:10px">
        <div slot="header">
          <span>其他配置</span>
        </div>
        <el-form-item label="达人分销佣金："  label-width="150px">
          <el-input style="width:100px" v-model="ruform.TalentCommissionRate" @blur="blurcommission" placeholder="0"
           oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input> %
        </el-form-item>
        <el-form-item label="商品达人分销链接：" label-width="150px">
          <el-form-item label="抖音平台：">
            <el-input style="width:300px" v-model="ruform.TikTokShareUrl" placeholder="请输入链接地址"></el-input>
          </el-form-item>
          <el-form-item label="快手平台：" style="margin-top:20px">
            <el-input style="width:300px" v-model="ruform.AAutoQuickerShareUrl" placeholder="请输入链接地址"></el-input>
          </el-form-item>
        </el-form-item>
      </el-card>
    </el-form>

    <div class="bottombox">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="save('ruform')">保存</el-button>
    </div>
    
    <!-- 选择商品-->
    <el-dialog title="选择商品" :visible.sync="selectProShow" v-if="selectProShow" width="1100px" @close="closedialog" class="dialog data-dialog">
      <select-produce :proCheckedId='ProductId' @getSelection='getSelectPros' :allReadyproductInfo='allReadyproductInfo'></select-produce>
    </el-dialog>
  </div>
</template>

<script>
import selectProduce from '@/views/broadcastManage/Selectlivegoods'
import config from "@/config"

import {
  checkNogoodslist
} from '@/api/TurnTomySelf.js'

import {
  shortVideoshortVideoInfo,
  shortVideoshortVideoSave,
} from "@/api/sv3.0.0"
export default {
  components: {
    selectProduce
  },
  data () {
    var checkInterceptionTime = (rule, value, callback) => {
      let two = /^\d+(\.\d{1,2})?$/
      if(!value||value<1.01||value>60||!two.test(value)){
        callback(new Error('时长请输入1.01秒~60秒之间，最多2位小数'))
      }else{
        callback()
      }
    };
    return {
      pageloading:false,
			imgUrl: config.IMG_BASE,
      imgApi: config.UPLOAD_IMG,
      videoApi:config.UPLOAD_VIDEO,
      fileApi:config.UPLOAD_ZIP,
      ruform:{
        Id:0,
        ProductId:'',
        Name:'',
        Describe:'',
        CoverImgUrl:'',
        ShortVideoSceneList:[
          {
            InterceptionTime:'',
            VideoList:[],
            listnum:0,
          }
        ],
        ShortVideoFileList:[],
        // AudioFile:'',
        // AudioFileName:'',
        // SubtitleFile:'',
        // SubtitleFileName:''
        TalentCommissionRate:'',
        TikTokShareUrl:'',
        AAutoQuickerShareUrl:'',
      },
      rules:{
        ProductId:[
          { required: true, message: '请选择带货商品', trigger: 'blur' },
        ],
        CoverImgUrl:[
          { required: true, message: '请上传视频封面图', trigger: 'blur' },
        ],
        InterceptionTime:[
          {required: true, validator: checkInterceptionTime, trigger: 'blur' }
        ],
        VideoList:[
          { required: true, message: '请上传该场景下的视频片段', trigger: 'blur' },
        ],
        AudioFile:[
          { required: true, message: '请完善音频文件', trigger: 'blur' },
        ],
        SubtitleFile:[
          { required: true, message: '请完善字幕文件', trigger: 'blur' },
        ]
      },
      ProductId:0,
      shoplist:[],
      selectProShow:false,
      allReadyproductInfo:null,
      waitlist:[],
      wait:false,
      goods:{},
    }
  },
  mounted () {
    if(this.$route.query.Id>0){
      this.getinfo()
    }
    this.ruform.ProductId = Number(this.$route.query.ProductId)||''
    this.ruform.Name = this.$route.query.Name||''
    if(this.$route.query.ProductId){
      this.allReadyproductInfo = {
        ProductId:Number(this.$route.query.ProductId),
        Name:this.$route.query.Name,
      }
      this.shoplist = [this.allReadyproductInfo]
    }
  },
  created(){
    // console.log(1)
  },
  methods: {
    blurcommission(e){
      if(this.ruform.TalentCommissionRate<0){
        this.ruform.TalentCommissionRate = 0
      }else if(this.ruform.TalentCommissionRate>100){
        this.ruform.TalentCommissionRate = 100
      }
    },
    pushShortVideoFile(){
      this.ruform.ShortVideoFileList.push({
        AudioFile:'',
        AudioFileName:'',
        AudioFileloading:false,
        SubtitleFile:'',
        SubtitleFileName:'',
        SubtitleFileloading:false,
      })
    },
    delShortVideoFile(i){
      this.ruform.ShortVideoFileList.splice(i,1)
    },
    playvideo(i,y){
      this.ruform.ShortVideoSceneList[i].VideoList[y].noplay = false
      console.log(this.ruform.ShortVideoSceneList[i].VideoList[y])
      this.$forceUpdate()
    },
    async getinfo(){
      try{
        this.pageloading = true
        let res = await shortVideoshortVideoInfo({
          Id:this.$route.query.Id
        })
        if(res.IsSuccess){
          let ruform = res.Result
          
          for(let item of ruform.ShortVideoSceneList){
            item.listnum = item.VideoList.length
          }
          ruform.ShortVideoSceneList = ruform.ShortVideoSceneList.map(v=>{
            v.VideoList = v.VideoList.map(x=>{
              x.noplay = true
              x.isold = true
              return x
            })
            return v
          })
          // console.log(ruform.ShortVideoSceneList)


          this.allReadyproductInfo = {
            ProductId:ruform.ProductId,
            Name:ruform.Name
          }
          this.shoplist = [this.allReadyproductInfo]

          ruform.ShortVideoFileList = ruform.ShortVideoFileList.map(v=>{
            v.AudioFileloading = false
            v.SubtitleFileloading = false
            return v
          })

          this.ruform = ruform
        }
        let res2 = await checkNogoodslist({
          KeyWord: '',
          ProductGenre: 3,
          ProductType: null,
          ProductState: null,
          ProductGroupIds: null,
          ProductBrandId:null,
          Skip: 0, // 略过数据
          Take: 9999, // 取的数据
        })
        if(res2.IsSuccess){
          this.goods = res2.Result.Results.find(v=>v.ProductId==this.ruform.ProductId)
          if(!this.goods||!this.goods.ProductId){
            this.ruform.ProductId = ''
          }
        }
      }finally{
        this.pageloading = false
      }
    },
    cancel(){
      this.$router.go(-1)
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          for(let item of this.ruform.ShortVideoSceneList){
            for(let value of item.VideoList){
              if(!value.VideoLink){
                this.$message.error('请等待视频文件上传成功后再保存')
                return
              }
            }
          }
          for(let item of this.ruform.ShortVideoFileList){
            if(item.AudioFileloading || item.SubtitleFileloading){
              this.$message.error('请等待音频/字幕文件上传成功后再保存')
              return
            }
          }
          if(!this.lessone()){
            return 
          }
          this.savemsg()
        } else {
          this.$nextTick(()=>{
            const element = document.querySelectorAll('.el-form-item__error')[0]
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          })
          this.$message.error('请完善短视频配置')
          return false;
        }
      });
    },
    lessone(){
      let videolist = this.$refs['video']
      // console.log(videolist)
      let errtime = []
      this.ruform.ShortVideoSceneList = this.ruform.ShortVideoSceneList.map((v,i)=>{
        v.VideoList = v.VideoList.map(x=>{
          videolist&&videolist.length&&videolist.map(m=>{
            let list = m.src.split('melyshop')
            // console.log(x.VideoLink,list[list.length-1],x.VideoLink==list[list.length-1])
            // console.log(x.isold)
            if(x.VideoLink==list[list.length-1]&&!x.isold){
              x.VideoTime = m.duration
              // console.log(x.VideoTime,'time')
              if((!x.VideoTime||x.VideoTime<=1)&&!errtime.includes(i)){
                // console.log(1,i)
                errtime.push(i)
              }
            }
          })
          return x
        })
        return v
      })
      // console.log(errtime)
      if(errtime&&errtime.length){
        this.$alert(
          errtime.map(v=>'场景'+(v+1)).join('、')+'下存在错误视频文件，错误原因：视频时长低于1秒。请修改视频文件，上传时长大于1秒的视频'
          , '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: action => {}
        });
        return false
      }
      return true
    },
    async savemsg(){
      try{
        this.pageloading = true
        let ruform = JSON.parse(JSON.stringify(this.ruform))
        ruform.Id = this.$route.query.Id
        ruform.noError = true
        if(!ruform.Describe){
          ruform.Describe = this.shoplist[0].Name
        }
        // console.log(ruform.ShortVideoSceneList)
        for(let item of ruform.ShortVideoSceneList){
          for(let item2 of item.VideoList){
            // console.log(item2)
            let videomsg = await this.getvideomsg(item2.VideoLink)
            console.log(videomsg)
            for(let key in videomsg){
              item2[key] = videomsg[key]
            }
          }
        }
        let res = await shortVideoshortVideoSave(ruform)
        // console.log(res)
        // res.IsSuccess = false
        // res.ErrorCode = 60000005
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.cancel()
        }else if(res.ErrorCode==60000005){
          // console.log(1)
          this.lessone()
        }else {
          this.$message.error(res.Message)
        }
      }finally{
        this.pageloading = false
      }
    },
    delfile(key){
      this.ruform[key] = ''
      if(key == 'AudioFile'){
        this.ruform.AudioFileName = ''
      }else if(key == 'SubtitleFile'){
        this.ruform.SubtitleFileName = ''
      }
    },
    onSuccessfile2(file,i){
      this.ruform.ShortVideoFileList[i].SubtitleFile = file[0]
      this.ruform.ShortVideoFileList[i].SubtitleFileloading = false
    },
    onSuccessfile(file,i){
      this.ruform.ShortVideoFileList[i].AudioFile = file[0]
      this.ruform.ShortVideoFileList[i].AudioFileloading = false
      console.log(this.ruform.ShortVideoFileList)
      // console.log(file[0])
    },
    beforeUploadfile2(file,i){
      // console.log(file.name.split('.'))
      const size = file.size / 1024 / 1024 < 10;
      // const testtype = ['audio/mp3']
      if(!file.name.split('.') || !file.name.split('.').length || file.name.split('.')[file.name.split('.').length-1].toLowerCase()!='srt'){
        this.$message.error('请选择字幕文件上传');
        return false
      }else if (!size) {
        this.$message.error('字幕文件大小请控制在10M以内');
        return false
      }
      this.ruform.ShortVideoFileList[i].SubtitleFileName = file.name
      this.ruform.ShortVideoFileList[i].SubtitleFileloading = true
    },
    beforeUploadfile(file,i){
      // console.log(file)
      const size = file.size / 1024 / 1024 < 10;
      const testtype = ['audio/mp3']
      if(!file.name.split('.') || !file.name.split('.').length || file.name.split('.')[file.name.split('.').length-1].toLowerCase()!='mp3'){
        this.$message.error('请选择音频文件上传');
        return false
      }else if (!size) {
        this.$message.error('音频文件大小请控制在10M以内');
        return false
      }
      this.ruform.ShortVideoFileList[i].AudioFileName = file.name
      this.ruform.ShortVideoFileList[i].AudioFileloading = true
    },
    delscenario(i){
      this.ruform.ShortVideoSceneList.splice(i,1)
    },
    pushscenario(){
      this.ruform.ShortVideoSceneList.push({
        InterceptionTime:'',
        VideoList:[],
        listnum:0,
        key:Math.random()
      })
    },
    delvideo(i,y){
      this.ruform.ShortVideoSceneList[i].listnum-=1
      this.ruform.ShortVideoSceneList[i].VideoList.splice(y,1)
      // console.log(this.ruform.ShortVideoSceneList[i])
    },
    async onSuccessvideo(file,rawfile,filelist,i){
      // console.log(file,rawfile.response)
      // console.log(rawfile.uid,filelist.map(v=>v.uid),rawfile)
      // console.log(index)
      for(let index in this.ruform.ShortVideoSceneList[i].VideoList){
        if(this.ruform.ShortVideoSceneList[i].VideoList[index].uid==rawfile.uid){
          this.ruform.ShortVideoSceneList[i].VideoList[index] = {
            VideoLink:file[0],
            VideoTime:0,
            loading:false,
            uid:rawfile.uid,
            noplay:true,
            isold:false
          }
        }
      }
      this.ruform.ShortVideoSceneList[i].VideoList = this.ruform.ShortVideoSceneList[i].VideoList.map((v,ind)=>{
        if(v.uid == rawfile.uid){
          v = {
            VideoLink:file[0],
            VideoTime:0,
            loading:false,
            uid:rawfile.uid,
            noplay:true,
            isold:false,
          }
        }
        return v
      })
      this.$forceUpdate()
    },
    async beforeUploadvideo(file,i){
      this.waitlist.push(...JSON.parse(JSON.stringify(this.$refs.videofile[0].uploadFiles)))
      const size = file.size / 1024 / 1024 < 1024;
      const testtype = ['video/mp4']
      const have = testtype.indexOf(file.type) >= 0
      // console.log(have)
      if(!have){
        this.$message.error('请选择视频上传');
        return false
      }else if (!size) {
        this.$message.error('视频大小请控制在1G及以内');
        return false
      }
      // console.log(this.ruform.ShortVideoSceneList[i].listnum)
      if(this.ruform.ShortVideoSceneList[i].listnum>=30){
        console.log(this.ruform.ShortVideoSceneList[i].listnum,'num')
        this.$message.error('每个场景最多可上传30个视频片段')
        return false
      }
      this.ruform.ShortVideoSceneList[i].listnum+=1
      this.ruform.ShortVideoSceneList[i].VideoList.push({
        VideoLink:'',
        VideoTime:0,
        loading:true,
        uid:file.uid,
        Width:0,
        Height:0
      })
    },
    getvideomsg(file){
      return new Promise((res)=>{
        // let video = document.createElement('video')
        // if(typeof file == 'string'){
        //   video.src = this.imgUrl+file
        // }else{
        //   video.src = URL.createObjectURL(file)
        // }
        // video.crossOrigin='Anonymous'
        // console.log(video.src,'src')
        // video.addEventListener('loadedmetadata',(e)=>{
        //   res({
        //     Width:video.videoWidth,
        //     Height:video.videoHeight
        //   })
        // })
        // URL.revokeObjectURL(file)
        // video.remove()

        var image = new Image()
				image.setAttribute('crossOrigin', 'anonymous')
				image.src = this.imgUrl+file +'?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast'
				image.onload = function() {
          res({
            Width:image.width,
            Height:image.height
          })
					
				}

      })
    },
    onSuccessimg(file){
      // console.log(file)
      this.ruform.CoverImgUrl = file[0]
    },
    beforeUploadimg(file){
      const size = file.size / 1024 / 1024 < 1;
      const testtype = ['image/png','image/gif','image/jpeg','image/jpg']
      if(testtype.indexOf(file.type) == -1){
        this.$message.error('请选择图片上传');
        return false
      }else if (!size) {
        this.$message.error('图片大小请控制在1M以内');
        return false
      }
    },
    visiblechange(val){
      if(val){
        this.ProductId = this.ruform.ProductId||0
        this.selectProShow = true
      }
    },
    getSelectPros(selection){
      // console.log(selection)
      if(!selection.ProductId){
        this.$message.error('请选择商品')
        return
      }
      this.shoplist = [selection]
      this.goods = selection
      this.allReadyproductInfo = selection
      this.ruform.ProductId = selection.ProductId
      this.selectProShow = false
    },
    closedialog(){
      this.ruform.ProductId = this.ruform.ProductId||''
    },
  }
}
</script>

<style lang="less" scoped>
.adjust-err{
  ::v-deep .el-form-item__error{
    top: 75%;
    left: 10px;
  }
}
.flex{
  display: flex;
}
.flex-end{
  align-items: flex-end;
}
.otherfile{
  border: 1px solid #EEEEEE;
  background: #FBFBFB;
  padding: 10px;
  min-width: 40%;
}
.size120{
  ::v-deep .el-upload--picture-card{
    width:120px;height:120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.defaultfont{
  font-size: 14px;
  line-height: 1.5;
  color: #606266;
}
.list-item{
  .item-titlebox{
    background: #F0F2F5;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-font{
      font-size: 14px;
      color:#303133;
      line-height:1.5;
    }
  }
  .item-body{
    padding: 30px 0px;
    .defaultfont{
      font-size: 14px;
      line-height: 1.5;
      color: #999999;
    }
    .rowflex-wrap{
      display: flex;
      flex-wrap: wrap;
      .videobox{
        position: relative;
        top:0;
        left:0;
        width:120px;height:120px;
        margin:10px 20px 10px 0px;
        border:1px dashed #c0ccda;
        border-radius: 6px;
        // border:1px solid red;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 500;
        .close{
          position: absolute;
          right:-10px;
          top:-10px;
          width:20px;height:20px;
          // border:1px solid black;
          border-radius: 50%;
          display: none;
          cursor: pointer;
          z-index: 2100 !important;
          .iconbox{
            width:100%;
            height:100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          i{
            font-size: 18px;
            color:#606266
          }
        }
        &:hover{
          .close{
            display: block;
          }
        }
      }
    }
  }
}
.introduce{
  margin-left:20px;
  margin-top:20px;
  div{
    font-size: 14px;
    color:#999999;
    line-height: 1.5;
  }
}
.flex{
  display: flex;
}
.uploadBox ::v-deep .el-upload--picture-card{
  width:160px;
  height:200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bottombox{
  position: fixed;
  bottom: 0px;
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: -5%;
  z-index: 999;
  background: white;
}
.flexRow{
  display: flex;
  align-items: center;
}
</style>
<style>
.zindex99{
  z-index: 99 !important;
  display: none;
}

</style>